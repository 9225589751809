<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 mt-4">
      <mat-card appearance="outlined">
        <h1 class="text-center">Uh Oh...</h1>
        <p class="text-center">Either something went wrong or this page doesn't exist!</p>
      </mat-card>
    </div>
  </div>
</div>
